/**
 * 创建一个上传对象
 * 使用 UploadAuth 上传方式
 */
export const createUploader = function createUploader (dataSet, addFileSuccess, onUploadSucceed, onUploadFailed, onUploadCanceled, onUploadProgress) {
    addFileSuccess || (addFileSuccess = uploadInfo => {
    });
    onUploadSucceed || (onUploadSucceed = function (uploadInfo) {
        console.log("onUploadSucceed: " + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object)
    });
    onUploadFailed || (onUploadFailed = function (uploadInfo, code, message) {
        console.log("onUploadFailed: file:" + uploadInfo.file.name + ",code:" + code + ", message:" + message)
    });
    onUploadCanceled || (onUploadCanceled = function (uploadInfo, code, message) {
        console.log("Canceled file: " + uploadInfo.file.name + ", code: " + code + ", message:" + message)
    });
    onUploadProgress || (onUploadProgress = function (uploadInfo, totalSize, progress) {
        console.log("onUploadProgress:file:" + uploadInfo.file.name + ", fileSize:" + totalSize + ", percent:" + Math.ceil(progress * 100) + "%")
    });
    
    var uploader = new AliyunUpload.Vod({
        timeout: 60000,
        partSize: 1048576,
        parallel: 5,
        retryCount: 3,
        retryDuration: 2,
        region: 'cn-shanghai',
        userId: 252547452959482624,
        addFileSuccess,
        // 开始上传
        onUploadstarted: function (uploadInfo) {
            uploader.setUploadAuthAndAddress(uploadInfo, dataSet.uploadAuth, dataSet.uploadAddress,dataSet.videoId)
        },
        // 文件上传成功
        onUploadSucceed,
        // 文件上传失败
        onUploadFailed,
        // 取消文件上传
        onUploadCanceled,
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress,
        // 上传凭证超时
        onUploadTokenExpired: function (uploadInfo) {
            // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
            // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
            axios
            .post(process.env.VUE_APP_API_URL_PREFIX+"/backend/web/upload/video", formData, {
              headers: {
                Authorization: getToken(),
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (res) {
              if (res.data.code == 1) {
                var dataSet = {};
                dataSet.uploadAuth = res.data.data.UploadAuth;
                dataSet.uploadAddress = res.data.data.UploadAddress;
                dataSet.videoId = res.data.data.VideoId;
                uploader.setUploadAuthAndAddress(uploadInfo, dataSet.uploadAuth, dataSet.uploadAddress,dataSet.videoId)
              }
            })
            .catch(function (err) {
              message.info(err);
            });
            console.log("视频上传超时!")
        },
        // 全部文件上传结束
        onUploadEnd: function (uploadInfo) {
            console.log("onUploadEnd: uploaded all the files")
        }
    })
    return uploader;
}
/*
var uploader = null;

$('#fileUpload').on('change', function (e) {
    var file = e.target.files[0]
    if (!file) {
        alert("请先选择需要上传的文件!")
        return
    }
    var Title = file.name
    var userData = '{"Vod":{}}'
    if (uploader) {
        uploader.stopUpload()
    }
    uploader = createUploader()
    uploader.addFile(file, null, null, null, userData)
})

// 第一种方式 UploadAuth 上传
$('#authUpload').on('click', function () {
    // 然后调用 startUpload 方法, 开始上传
    if (uploader !== null) {
        uploader.startUpload()
    }
})

// 暂停上传
$('#pauseUpload').on('click', function () {
    if (uploader !== null) {
        uploader.stopUpload()
    }
})

//恢复上传
$('#resumeUpload').on('click', function () {
    if (uploader !== null) {
        uploader.startUpload()
    }
})
*/

// $contentTypeVideoLabel.on('change', '[type="file"]', function (e) {
//     var file = e.target.files[0];
//     if (!file) {
//         alert("请先选择需要上传的文件!");
//         return false;
//     }
//     var limitType = ['video/mp4'];
//     if (limitType.indexOf(file.type.toLowerCase()) === -1) {
//         alert('请选择支持的视频文件');
//         $contentTypeVideoLabel.find('input').replaceWith('<input type="file" accept=".mp4">');
//         return false;
//     }
//     var userData = '{"Vod":{}}';
//     uploader && uploader.stopUpload();
//     uploader = createUploader('title_' + file.name, file.name, function (uploadInfo) {
//         //文件成功加入上传队列
//         uploader.startUpload();
//         $(':submit').attr('disabled', 'disabled').attr('type', 'button').addClass('disabled');
//     }, function (uploadInfo) {
//         //文件上传成功
//         $uploadVideoUrl.val(uploadInfo.object);
//         $uploadVideoId.val(uploadInfo.videoId);
//         $videoContainer.html('' +
//             //'<video src="http://video.uulian.com/' + uploadInfo.object + '" style="width:100%;height:100%"></videl>' +
//             '<video src="<?=$vod_file_host?>' + uploadInfo.object + '" style="width:100%;height:100%"></video>' +
//             '');
//             changeUploadStatus('uploaded');
//             $uploadProgress.text('正在上传：0%');
//             $(':button').removeAttr('disabled').attr('type', 'submit').removeClass('disabled');
//     }, function (uploadInfo, code, message) {
//         //文件上传失败
//         $(':button').removeAttr('disabled').attr('type', 'submit').removeClass('disabled');
//     }, null, function (uploadInfo, totalSize, progress) {
//         //上传进度
//         $uploadProgress.text('正在上传：' + Math.ceil(progress * 100) + "%");
//     });
//     uploader.addFile(file, null, null, null, userData);
//     changeUploadStatus('uploading');
// });