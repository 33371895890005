<template>
  <n-upload
    id="UploadMap"
    :action="apiUrlPrefix+'/backend/web/upload/index'"
    v-model:file-list="fileList"
    @change="handleUploadChange"
    @remove="handleRemove"
    @update:file-list="handleFileListChange"
    @before-upload="upbefore"
    @handleDrop="handleDrop"
    :default-upload="false"
    accept="image/png, image/jpg, application/pdf"
    :headers="Authorization"
    :max="maxNum"
    :multiple="ZHEN"
    
  >
    <n-upload-dragger>
      <div style="margin-bottom: 12px">
        <n-icon size="48" :depth="3"> </n-icon>
      </div>
      <n-text style="font-size: 16px">点击或者拖动文件到该区域来上传</n-text>
      <n-p depth="3" style="margin: 8px 0 0 0"> 只能上传PNG、JPG、PDF</n-p>
    </n-upload-dragger>
    <p class="c999">注意 : 多文件同时上传时, 传入的文件按上传完成顺序排列</p>
  </n-upload>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useMessage } from "naive-ui";
import { getToken } from "@/utils/auth.js";
import { ArchiveOutline as ArchiveIcon } from "@vicons/ionicons5";
import axios from "axios";
import { resStatusEnum } from '@/enumerators/http.js';
import { uploadIndex } from '@/api/upload.js';
import env from '@/settings/env.js';
const { apiUrlPrefix } = env;

const { SUCCESS } = resStatusEnum;


export default defineComponent({
  components: {
    ArchiveIcon,
  },
  // 父组件传来的值
  props: {
    maxNum: String,
    size: String,
    removeUrl: String,
  },
  setup(props, context) {
    const { attrs, slots, emit } = context;
    const message = useMessage();
    const fileListRef = ref(attrs.imaData);
    const OSS = require("ali-oss");
    var imagedata = [];

    var size = Number({ ...props }.size); // 文件大小限制
    var maxNum = Number({ ...props }.maxNum);
    var removeUrl = { ...props }.removeUrl; // 删除的接口信息
    return {
      token: getToken(),
      ZHEN: true,
      // 上传所需Authorization
      Authorization: {
        Authorization: getToken(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
      maxNum: maxNum, // 最大上传数量
      fileList: fileListRef, // 文件列表

      // 组件变化时的回调
      handleUploadChange({ fileList }) {
        fileListRef.value = fileList;
        message.info("组件变化时的回调");
      },

      // 上传之前
      upbefore(file) {
        // message.info("上传之前");
        var fileData = file.file.file;
        var isJPEG = fileData.name.split(".")[1] === "jpeg";
        var isJPG = fileData.name.split(".")[1] === "jpg";
        var isPNG = fileData.name.split(".")[1] === "png";
        var isPDF = fileData.name.split(".")[1] === "pdf";
        var format = fileData.name.split(".")[1];
        console.log("fileData:",fileData)
        var isLt20MK = fileData.size / 1024 / 1024;
        if (!isJPG && !isJPEG && !isPNG && !isPDF) {
          message.info("上传文件只能是 JPEG/JPG/PNG 格式!");
          return;
        }
        if (isLt20MK > size) {
          message.info("单张文件大小不能超过" + size + "M !");
          return;
        }
        // console.log("file", file);
        // uploadIndex()
        axios
          .get(apiUrlPrefix+"/backend/web/upload/index", {
            id: 1,
            headers: {
              Authorization: getToken(),
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then(function (res) {
            if (res.status == 200) {
              // console.log("res:", res.data.data);
              var fileName =
                res.data.data.folder +
                "/yunpingtai" +
                `${Date.parse(new Date())}` +
                "." +
                format; //定义唯一的文件名
              // console.log("client", client);
              let client = new OSS({
                region: res.data.data.region,
                accessKeyId: res.data.data.accessKeyId,
                accessKeySecret: res.data.data.accessKeySecret,
                bucket: res.data.data.bucket,
                stsToken: res.data.data.securityToken,
              });

              client
                .multipartUpload(fileName, fileData)
                .then(function (result) {
                  // console.log("result:", result);
                  var fileUrl = result.res.requestUrls[0];
                  imagedata.push({
                    name: fileName,
                    url: fileUrl,
                    reallyname: fileData.name,
                  });
                  // 传值给父组件
                  context.emit("imagedata", imagedata);
                  // console.log("imagedata:", imagedata);
                })
                .catch(function (err) {
                  message.info(err);
                });
            }
          })
          .catch(function (err) {
            message.info(err);
          });
          
      },

      // 文件上传
      handleDrop(file) {
        // console.log("文件上传file:", file);
      },

      // 文件删除
      handleRemove(e) {
        // console.log("文件删除:", e.file);
        // console.log("文件删除前imagedata:", imagedata);
        // 新上传删除
        for (var i = 0; i < imagedata.length; i++) {
          var isName = imagedata[i].reallyname;
          if (e.file.name == imagedata[i].reallyname) {
            imagedata = imagedata.splice(i, 1);
            console.log("文件删除后imagedata:", imagedata);
            // 删除后把新的数据传给父组件
            context.emit("imagedata", imagedata);
          }
        }

        // 旧数据删除
        // axios
        //   .post(removeUrl, {
        //     id: 1
        //   })
        //   .then(function (res) {
        //     if (res.status == 200) {
        //       message.info("文件删除成功!");
        //     }
        //   })
        //   .catch(function (err) {
        //     console.log(err);
        //   });
      },

      handleFileListChange(value) {
        // message.info("是的，file-list 的值变了");
      },
    };
  },
});
</script>
<style lang="less">
  #UploadMap{
    max-width: 360px;
}

.n-progress-graph-line-rail{
    height:6px !important;
    border-radius: 5px !important;
    background: #4bd863 !important;
}

.n-progress-graph-line-fill{
    height:6px !important;
    border-radius: 5px !important;
    background: #4bd863 !important;
}

.n-upload-trigger{
    width: 100%;
}

#UploadMap .n-icon.n-icon--depth.n-icon--color-transition{
    background: url(../../../public/img/upload/9789225d.png);
}

.c999{
    color: #999;
    font-size: 14px;
}

#pictureUpload .n-upload-trigger.n-upload-trigger--image-card .n-upload-dragger{
    width: 100px;
    height:100px;
    position: relative;
    background: url(../../../public/img/upload/C6173F69.png) no-repeat;
    background-size: 100%;
}

#pictureUpload .n-upload-trigger.n-upload-trigger--image-card:first-child{
    display: block;
}
#pictureUpload .n-upload-trigger.n-upload-trigger--image-card{
    display: none;
}
.title-up-text{
    font-size: 14px;
    color: #999;
    width: 200px;
    position: absolute;
    left:120px;
}
</style>