<template>
  
  <n-upload
    id="UploadMap"
    action=""
    v-model:file-list="fileList"
    @change="handleUploadChange"
    @remove="handleRemove"
    @update:file-list="handleFileListChange"
    @before-upload="upbefore"
    @handleDrop="handleDrop"
    :default-upload="false"
    :max="maxNum"
    :multiple="false"
  >
    <n-upload-dragger>
      <div style="margin-bottom: 12px">
        <n-icon size="48" :depth="3"> </n-icon>
      </div>
      <n-text style="font-size: 16px">点击或者拖动文件到该区域来上传</n-text>
      <n-p depth="3" style="margin: 8px 0 0 0">
        限 .pdf .doc .ppt .doc .docx .xls .xlsx .ppt.pptx .png .jpg .mp4
        格式文件</n-p
      >
    </n-upload-dragger>
    <p class="c999">注意 : 多文件同时上传时, 传入的文件按上传完成顺序排列</p>
  </n-upload>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useMessage } from "naive-ui";
import { getToken } from "@/utils/auth.js";
import { ArchiveOutline as ArchiveIcon } from "@vicons/ionicons5";
import axios from "axios";
import env from '@/settings/env.js';
const { apiUrlPrefix } = env;

import { createUploader } from "@/utils/videoupload";

export default defineComponent({
  components: {
    ArchiveIcon,
  },
  // 父组件传来的值
  props: {
    maxNum: String,
    size: String,
    removeUrl: String,
  },
  setup(props, context) {
    const { attrs, slots, emit ,expose} = context;
    const message = useMessage();
    const fileListRef = ref(attrs.imaData);
    const OSS = require("ali-oss");
    var imagedata = [];
    var size = Number({ ...props }.size); // 文件大小限制
    var maxNum = Number({ ...props }.maxNum);
    var removeUrl = { ...props }.removeUrl; // 删除的接口信息

    let _uploader = null;
    // 添加文件成功
    const addFileSuccess = function(res){
      // console.log("视频添加至上传队列6:",res)
      // 开启文件上传
      _uploader.startUpload();
    }
    // 视频上传成功
    const onUploadSucceed = function(res){
       // res.bucket
      let htps1 = "https://"
      let htps2 = res.bucket + '.'
      let htps3 = 'oss-cn-shanghai.aliyuncs.com/'
      let htps4 = htps1 + htps2 + htps3
      // console.log("视频地址:",htps4)
      let uploadId = res.checkpoint.uploadId
      
      imagedata.push({
        type:'video',               // 类型 file:文件  video:视频
        size:res.file.size,         // 文件大小
        name: res.object,           // 文件存储名
        url: htps4,                 // 文件地址
        reallyname: res.file.name,  // 原文件名字
        fileId:uploadId             // 视频ID(阿里云)
      });
      // 传值给父组件
      // context.emit("imagedata", imagedata);
      console.log("传值给父组件imagedata:",imagedata)
    }
    // 视频上传失败
    const onUploadFailed = function(res){
      // console.log("视频上传失败6:",res)
      message.info("视频上传失败, 请重试");
    }
    // 取消视频上传
    const onUploadCanceled = function(res){
      // console.log("取消视频上传6:",res)
    }
    // 视频上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
    const onUploadProgress = function(res){
      // console.log("视频上传进度5:",res)
      // console.log("视频上传进度6:",fileListRef._value)
    }
    expose({
      addFileSuccess,
      onUploadSucceed,
      onUploadFailed,
      onUploadCanceled,
      onUploadProgress
    })
    
    return {
      token: getToken(),
      maxNum: maxNum, // 最大上传数量
      fileList: fileListRef, // 文件列表

      // 组件变化时的回调
      handleUploadChange({ fileList }) {
        fileListRef.value = fileList;
        message.info("组件变化时的回调");
      },

      // 上传之前
      upbefore(file) {
        
        var fileData = file.file.file;

        var format = fileData.name.split(".")[1];

        var isLt20MK = fileData.size / 1024 / 1024;

        if (isLt20MK > size) {
          message.info("单张文件大小不能超过" + size + "M !");
          return;
        }
        // .pdf .doc .ppt  .doc .docx .xls .xlsx .ppt.pptx .png .jpg .mp4
        if (
          format != "pdf" &&
          format != "doc" &&
          format != "ppt" &&
          format != "docx" &&
          format != "xls" &&
          format != "xlsx" &&
          format != "ppt" &&
          format != "pptx" &&
          format != "png" &&
          format != "jpg" &&
          format != "mp4" &&
          format != "mp3"
        ) {
          message.info("不支持该文件格式!");
          return;
        }
        // message.info("上传之前",createUploader(file.file));
        if (format == "mp4" || format == "mp3") {

          let videoName = `${Date.parse(new Date())}` + fileData.name; //定义唯一的文件名
          const formData = new FormData();
          formData.append("title", videoName);
          formData.append("filename", fileData.name);
          axios
            .post(apiUrlPrefix+"/backend/web/upload/video", formData, {
              headers: {
                Authorization: getToken(),
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (res) {
              if (res.data.code == 1) {
                var dataSet = {};
                dataSet.uploadAuth = res.data.data.UploadAuth;
                dataSet.uploadAddress = res.data.data.UploadAddress;
                dataSet.videoId = res.data.data.VideoId;
                
                _uploader = createUploader(
                  dataSet,
                  addFileSuccess,
                  onUploadSucceed,
                  onUploadFailed,
                  onUploadCanceled,
                  onUploadProgress
                );
                const userData = '{"Vod":{}}';
                // 将文件添加到上传队列 -> 触发 addFileSuccess 回调函数
                _uploader.addFile(fileData, null, null, null, userData);
              }
            })
            .catch(function (err) {
              message.info(err);
            });
        } else {
          // 上传文件
          axios
            .get(apiUrlPrefix+"/backend/web/upload/index", {
              id: 1,
              headers: {
                Authorization: getToken(),
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then(function (res) {
              if (res.status == 200) {
                // console.log("res:", res.data.data);
                var fileName =
                  res.data.data.folder +
                  "/yunpingtai" +
                  `${Date.parse(new Date())}` +
                  "." +
                  format; //定义唯一的文件名
                // console.log("client", client);
                let client = new OSS({
                  region: res.data.data.region,
                  accessKeyId: res.data.data.accessKeyId,
                  accessKeySecret: res.data.data.accessKeySecret,
                  bucket: res.data.data.bucket,
                  stsToken: res.data.data.securityToken,
                });

                client
                  .multipartUpload(fileName, fileData)
                  .then(function (result) {
                    var fileUrl = result.res.requestUrls[0];
                    let type = 'file'
                    imagedata.push({
                      type:type,          // 类型 file:文件  video:视频
                      size:fileData.size, // 文件大小
                      name: fileName,     // 文件存储名
                      url: fileUrl,       // 文件地址
                      reallyname: fileData.name,// 原文件名字
                    });
                    // 传值给父组件
                    context.emit("imagedata", imagedata);
                    // console.log("imagedata:", imagedata);
                  })
                  .catch(function (err) {
                    message.info(err);
                  });
              }
            })
            .catch(function (err) {
              message.info(err);
            });
        }
      },

      // 文件上传
      handleDrop(file) {
        // console.log("文件上传file:", file);
      },

      // 文件删除
      handleRemove(e) {
        // console.log("文件删除:", e.file);
        // console.log("文件删除前imagedata:", imagedata);
        // 新上传删除
        for (var i = 0; i < imagedata.length; i++) {
          var isName = imagedata[i].reallyname;
          if (e.file.name == imagedata[i].reallyname) {
            imagedata = imagedata.splice(i, 1);
            // 删除后把新的数据传给父组件
            context.emit("imagedata", imagedata);
          }
        }

        // 旧数据删除
        // axios
        //   .post(removeUrl, {
        //     id: 1
        //   })
        //   .then(function (res) {
        //     if (res.status == 200) {
        //       message.info("文件删除成功!");
        //     }
        //   })
        //   .catch(function (err) {
        //     console.log(err);
        //   });
      },

      handleFileListChange(value) {
        message.info("是的，file-list 的值变了");
      },

      
    };
  }
});
</script>
<style lang="less">
  #UploadMap{
    max-width: 360px;
}

.n-progress-graph-line-rail{
    height:6px !important;
    border-radius: 5px !important;
    background: #4bd863 !important;
}

.n-progress-graph-line-fill{
    height:6px !important;
    border-radius: 5px !important;
    background: #4bd863 !important;
}

.n-upload-trigger{
    width: 100%;
}

#UploadMap .n-icon.n-icon--depth.n-icon--color-transition{
    background: url(../../../public/img/upload/9789225d.png);
}

.c999{
    color: #999;
    font-size: 14px;
}

#pictureUpload .n-upload-trigger.n-upload-trigger--image-card .n-upload-dragger{
    width: 100px;
    height:100px;
    position: relative;
    background: url(../../../public/img/upload/C6173F69.png) no-repeat;
    background-size: 100%;
}

#pictureUpload .n-upload-trigger.n-upload-trigger--image-card:first-child{
    display: block;
}
#pictureUpload .n-upload-trigger.n-upload-trigger--image-card{
    display: none;
}
.title-up-text{
    font-size: 14px;
    color: #999;
    width: 200px;
    position: absolute;
    left:120px;
}
</style>